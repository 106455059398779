import React, { useState, useContext } from 'react';
import './knowledgeMap.css';
import { AuthContext } from '../../context/AuthContext';

const KnowledgeMap: React.FC = () => {
    const [dashboards] = useState<string[]>([
        'https://app.powerbi.com/reportEmbed?reportId=177a250c-75fd-4636-8aac-579decfbe8a8&autoAuth=true&ctid=14cb4ab4-62b8-45a2-a944-e225383ee1f9'
    ]);
    const authContext = useContext(AuthContext);
    const user = authContext?.user;

    return (
        <div className="home-container">
            <span className="text_big">Mapa de conocimiento</span>
            <div className="dashboard-container">
                {dashboards.map((dashboard, index) => (
                    <iframe
                        key={index}
                        src={dashboard}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        allowFullScreen
                        title={`Power BI Dashboard ${index + 1}`}
                    ></iframe>
                ))}
            </div>
        </div>
    );
};

export default KnowledgeMap;
