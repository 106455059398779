import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import './home.css';

const Home = () => {
    const authContext = useContext(AuthContext);
    const user = authContext?.user;

    return (
        <div className="home-container">
            <header className="home-header">
                <h1 className="home-title">Bienvenido, {user?.nombre || 'Nombre Usuario'}!</h1>
                <p className="home-description">Explora nuestra plataforma y descubre lo que tenemos para ti.</p>
            </header>

            <section className="home-content">
                <div className="home-image-container">
                    <img src="/assets/inetum.jpg" alt="Imagen destacada" className="home-image" />
                </div>
                <div className="home-welcome-text">
                    <h2 className="home-subtitle">Descubre nuestros servicios</h2>
                    <p className="home-text">Ofrecemos una amplia variedad de funcionalidades diseñadas para mejorar tu experiencia. ¡No te lo pierdas!</p>
                </div>
            </section>
        </div>
    );
};

export default Home;
