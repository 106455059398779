import axios, { AxiosRequestConfig } from 'axios';
import config from '../configuration/config';

interface FetchApiParams {
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    params?: Record<string, any>;
    body?: Record<string, any>;
    useToken?: boolean;
}

const fetchApi = async ({
                            endpoint,
                            method = 'GET',
                            params = {},
                            body = {},
                            useToken = true,
                        }: FetchApiParams) => {

    try {
        const token = useToken ? `Bearer ${localStorage.getItem('token')}` : undefined;

        const requestConfig: AxiosRequestConfig = {
            url: `${config.API_URL}${endpoint}`,
            method,
            headers: {
                'Content-Type': 'application/json',
                ...(token && { Authorization: token }),
            },
            params: method === 'GET' ? params : undefined,
            data: method !== 'GET' ? body : undefined,
        };

        const response = await axios(requestConfig);

        return response.data;
    } catch (error: any) {
        throw error;
    }
};

export default fetchApi;
