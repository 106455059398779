// src/pages/NotFound.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './NotFound.css'; // Asegúrate de tener este archivo de estilos

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(-1);
    };

    return (
        <div className="not-found">
            <div className="icon-container">
                <i className="fas fa-sad-tear"></i>
            </div>
            <h1 className="title">404</h1>
            <h2 className="subtitle">Página no encontrada</h2>
            <p className="description">
                La página que estás buscando no existe o ha sido movida.
            </p>
            <button className="back-button" onClick={handleRedirect}>
                <FaArrowLeft /> Regresar
            </button>
        </div>
    );
};

export default NotFound;
