import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import fetchApi from '../../api/fetchApi';
import { useLocation, useNavigate } from 'react-router-dom';
import './formStyles.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const validationSchema = Yup.object({
    usrUsuario: Yup.string()
        .email('Dirección de correo electrónico inválida')
        .matches(/^[^\s@]+@inetum\.com$/, 'El correo electrónico debe ser de dominio @inetum.com')
        .required('Este campo es requerido'),
    usrClave: Yup.string()
        .min(3, 'La contraseña debe tener al menos 3 caracteres')
        .required('Este campo es requerido'),
    rolId: Yup.string().required('Este campo es requerido'),
    perId: Yup.string().required('Este campo es requerido'),
    usrEstado: Yup.string().required('Este campo es requerido'),
});

const formUser = {
    usrUsuario: { type: 'text', label: 'Usuario', disabled: [] },
    usrClave: { type: 'password', label: 'Clave', disabled: [] },
    rolId: { type: 'select', label: 'Rol', items: [], disabled: [] },
    perId: { type: 'select', label: 'Persona', items: [], disabled: [] },
    usrEstado: { type: 'select', label: 'Estado', items: [{ label: 'Activo', value: '1' }, { label: 'Inactivo', value: '0' }], disabled: [] }
};

type FormField = {
    type: string;
    label: string;
    items?: { value: string; label: string }[];
    disabled: string[];
};

type FormConfig = Record<string, FormField>;

const CreateUser: React.FC<{ endpoint: string; mode: 'create' | 'edit'; }> = ({ endpoint, mode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form, setForm] = useState<FormConfig>(formUser);
    const [values, setValues] = useState<Record<string, any>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async () => {
            try {
                await Promise.all([getRoles(), getPersons()]);
                if (mode === 'edit' && location.state?.user) {
                    const user = location.state.user;
                    setValues({
                        ...user,
                        usrEstado: user.usrEstado ? '1' : '0'
                    });
                }
            } catch (error) {
                console.error('Error getting data:', error);
            }
        };
        loadData();
    }, [mode, location.state?.user]);

    const getRoles = async () => {
        try {
            const roles = (await fetchApi({ endpoint: '/roles', method: 'GET' })).map((rol: any) => ({
                label: rol.rolNombre || '',
                value: rol.rolId.toString()
            }));
            setForm(prevForm => ({
                ...prevForm,
                rolId: { ...prevForm.rolId, items: roles }
            }));
        } catch (error) {
            console.error('Error getting roles:', error);
        }
    };

    const getPersons = async () => {
        try {
            const data = await fetchApi({ endpoint: '/personas', method: 'GET', params: { size: 30, justActive: true } });
            const content = (data.content || []).map((p: any) => ({
                label: `${p.perNombre || ''} ${p.perApellido || ''}`,
                value: p.perId.toString()
            }));
            setForm(prevForm => ({
                ...prevForm,
                perId: { ...prevForm.perId, items: content }
            }));
        } catch (error) {
            console.error('Error getting people:', error);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const validateField = (name: string, value: any) => {
        try {
            validationSchema.validateSyncAt(name, { [name]: value });
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        } catch (validationError) {
            const typedError = validationError as Yup.ValidationError;
            if (typedError.message) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: typedError.message
                }));
            } else {
                console.error('Unexpected error during validation:', typedError);
            }
        }
    };

    const validate = () => {
        try {
            validationSchema.validateSync(values, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            if (validationErrors instanceof Yup.ValidationError) {
                const formattedErrors = validationErrors.inner.reduce((acc: Record<string, string>, err: Yup.ValidationError) => {
                    acc[err.path as string] = err.message;
                    return acc;
                }, {});
                setErrors(formattedErrors);
            }
            return false;
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!validate()) {
            setIsSubmitting(false);
            return;
        }

        const transformedValues = {
            usrUsuario: values.usrUsuario,
            usrClave: values.usrClave,
            rolId: { rolId: Number(values.rolId) },
            perId: { perId: Number(values.perId) },
            usrEstado: values.usrEstado === '1',
            usrId: location.state.user?.usrId
        };

        const requestMethod = mode === 'create' ? 'POST' : 'PUT';
        const url = mode === 'create'
            ? endpoint
            : `${endpoint}`;

        try {
            const response = await fetchApi({
                endpoint: url,
                method: requestMethod,
                body: transformedValues,
                useToken: true
            });

            if (response) {
                alert(`${mode === 'create' ? 'Usuario creado' : 'Usuario actualizado'} exitosamente.`);
                navigate('/users');
            } else {
                alert(`Error ${mode === 'create' ? 'creando' : 'actualizando'} el usuario, revisa los datos.`);
            }
        } catch (error: any) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || `Error ${mode === 'create' ? 'creando' : 'actualizando'} el usuario`;
                alert(errorMessage);
            } else {
                console.error('Unexpected error:', error);
                alert('Error inesperado.');
            }
        }

        setIsSubmitting(false);
    };

    return (
        <div className="layout-app user-crud">
            <section className="header_crud">
                <button className="button-back" onClick={() => navigate(-1)}>←</button>
                <h4>{mode === 'create' ? 'Crear Usuario' : 'Editar Usuario'}</h4>
                <button
                    type="submit"
                    className="button-submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Enviando...' : mode === 'create' ? 'Crear' : 'Editar'}
                </button>
            </section>
            <div className="form-user">
                {Object.keys(form).map(key => {
                    const field = form[key as keyof FormConfig];
                    if (!field) return null;

                    // Deshabilitar usrUsuario y usrClave en modo edición
                    const isDisabled = (mode === 'edit' && (key === 'usrUsuario' || key === 'usrClave'));

                    return (
                        <div key={key} className="item-form">
                            <label htmlFor={key}>
                                {field.label} <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="input-container">
                                {field.type === 'text' || field.type === 'number' || field.type === 'password' ? (
                                    <>
                                        <input
                                            type={field.type}
                                            id={key}
                                            name={key}
                                            value={values[key] || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isDisabled}
                                            required
                                        />
                                        {key === 'usrClave' && (
                                            <button
                                                type="button"
                                                className="show-password-button"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                                            </button>
                                        )}
                                    </>
                                ) : field.type === 'select' ? (
                                    <div className="custom-select-wrapper">
                                        <select
                                            id={key}
                                            name={key}
                                            value={values[key] || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isDisabled}
                                            required
                                        >
                                            <option value="" label="Seleccionar"/>
                                            {field.items?.map(item => (
                                                <option key={item.value} value={item.value} label={item.label}/>
                                            ))}
                                        </select>
                                    </div>
                                ) : null}
                                {errors[key] && <span className="error-message">{errors[key]}</span>}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CreateUser;
