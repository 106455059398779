import axios from 'axios';
import config from '../configuration/config';

interface GetUsersParams {
    token: string;
    modelTabs: number;
    page: number;
    size: number;
    searchTerm: string;
}

interface UserResponseData {
    content: any[];
    totalElements: number;
    totalPages: number;
    currentPage: number;
}

interface UserData {
    id: number;
    name: string;
    email: string;
}

export const getUsers = async ({
                                   token,
                                   modelTabs,
                                   page,
                                   size,
                                   searchTerm
                               }: GetUsersParams): Promise<UserResponseData> => {
    try {
        const endpoint = modelTabs === 0 ? '/usuarios' : '/personas';
        const response = await axios.get<UserResponseData>(`${config.API_URL}${endpoint}`, {
            params: { size, page, searchTerm },
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

// Function to create a user or person
export const createUser = async (
    token: string,
    data: UserData,
    modelTabs: number // 0 for 'usuarios', 1 for 'personas'
) => {
    try {
        const endpoint = modelTabs === 0 ? '/usuarios' : '/personas';
        await axios.post(`${config.API_URL}${endpoint}`, data, {
            headers: { Authorization: `Bearer ${token}` }
        });
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

// Function to update a user or person
export const updateUser = async (
    token: string,
    id: number,
    data: UserData,
    modelTabs: number // 0 for 'usuarios', 1 for 'personas'
) => {
    try {
        const endpoint = modelTabs === 0 ? `/usuarios/${id}` : `/personas/${id}`;
        await axios.put(`${config.API_URL}${endpoint}`, data, {
            headers: { Authorization: `Bearer ${token}` }
        });
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};
