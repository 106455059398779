import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import SimpleBar from 'simplebar-react';
interface ModalTableProps {
    isOpen: boolean;
    onClose: () => void;
    data: string[][];
}

const ModalTable: React.FC<ModalTableProps> = ({ isOpen, onClose, data }) => {
    return (
        <Modal show={isOpen} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Detalles del Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data.length === 1 && data[0].length === 1 ? (
                    <p className="text-center">{data[0][0]}</p>
                ) : (
                    <SimpleBar style={{ maxHeight: '600px' }}>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                {data[0].map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </SimpleBar>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={onClose}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalTable;
