import React from 'react';

interface NotAccessProps {
    innerView?: boolean;
}

const NotAccess: React.FC<NotAccessProps> = ({ innerView }) => (
    <div className="not-access">
        <h2>No tienes acceso a esta sección</h2>
    </div>
);

export default NotAccess;
