import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { getToken, logout } from '../api/auth';

interface AuthContextType {
    user: any;
    setUser: (user: any) => void;
    token: string | null;
    setToken: (token: string | null) => void;
    handleLogout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<any>(null);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const storedToken = getToken();
        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                setUser(decodedToken);
                setToken(storedToken);
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
            setUser(null);
            setToken(null);
            localStorage.removeItem('token');
            Cookies.remove('token');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, setUser, token, setToken, handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};
